.src-components-SplashScreen---splash--1AYrL {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 100%;
  height: 100vh;
}

.src-components-SplashScreen---splash-logo--27hxy {
  height: 4rem;
  opacity: 1;
}

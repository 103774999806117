
  .src-components-Checkout-CheckoutForm---payment-wrapper--76bMr form {
    padding: 2em 5px;
    min-height: 375px;
  }

.src-components-Checkout-CheckoutForm---payment-wrapper--76bMr .src-components-Checkout-CheckoutForm---payment-message--1UnXf {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }

.src-components-Checkout-CheckoutForm---payment-wrapper--76bMr .src-components-Checkout-CheckoutForm---payment-element--mYYPw {
    margin-bottom: 24px;
    min-height: 245px;
  }

.src-components-Checkout-CheckoutForm---payment-wrapper--76bMr {

  /* Buttons and links */
}

.src-components-Checkout-CheckoutForm---payment-wrapper--76bMr button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%
  }

.src-components-Checkout-CheckoutForm---payment-wrapper--76bMr button:hover {
      -webkit-filter: contrast(115%);
              filter: contrast(115%);
    }

.src-components-Checkout-CheckoutForm---payment-wrapper--76bMr button:disabled {
      opacity: 0.5;
      cursor: default;
    }

.src-components-Checkout-CheckoutForm---payment-wrapper--76bMr {

  /* spinner/processing state, errors */
  & .src-components-Checkout-CheckoutForm---spinner--2jc9w,
  .src-components-Checkout-CheckoutForm---spinner--2jc9w:before,
  .src-components-Checkout-CheckoutForm---spinner--2jc9w:after {
    border-radius: 50%;
  }
}

.src-components-Checkout-CheckoutForm---payment-wrapper--76bMr .src-components-Checkout-CheckoutForm---spinner--2jc9w {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0)
  }

.src-components-Checkout-CheckoutForm---payment-wrapper--76bMr .src-components-Checkout-CheckoutForm---spinner--2jc9w:before {
      position: absolute;
      content: "";
      width: 10.4px;
      height: 20.4px;
      background: #5469d4;
      border-radius: 20.4px 0 0 20.4px;
      top: -0.2px;
      left: -0.2px;
      -webkit-transform-origin: 10.4px 10.2px;
      -ms-transform-origin: 10.4px 10.2px;
          transform-origin: 10.4px 10.2px;
      -webkit-animation: src-components-Checkout-CheckoutForm---loading--nUB27 2s infinite ease 1.5s;
      animation: src-components-Checkout-CheckoutForm---loading--nUB27 2s infinite ease 1.5s;
    }

.src-components-Checkout-CheckoutForm---payment-wrapper--76bMr .src-components-Checkout-CheckoutForm---spinner--2jc9w:after {
      position: absolute;
      content: "";
      width: 10.4px;
      height: 10.2px;
      background: #5469d4;
      border-radius: 0 10.2px 10.2px 0;
      top: -0.1px;
      left: 10.2px;
      -webkit-transform-origin: 0px 10.2px;
      -ms-transform-origin: 0px 10.2px;
          transform-origin: 0px 10.2px;
      -webkit-animation: src-components-Checkout-CheckoutForm---loading--nUB27 2s infinite ease;
      animation: src-components-Checkout-CheckoutForm---loading--nUB27 2s infinite ease;
    }

@-webkit-keyframes src-components-Checkout-CheckoutForm---loading--nUB27 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes src-components-Checkout-CheckoutForm---loading--nUB27 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
    .src-components-Checkout-CheckoutForm---payment-wrapper--76bMr form {
      width: 80vw;
      min-width: 0;
      min-width: initial;
    }
}

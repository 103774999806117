.src-components-Notification---notification--wPJLK {
  margin-right: 45px;
  /* background: white;
  padding: 0.75rem 1.5rem;
  border: 1px solid var(--color-grey-50);
  border-radius: var(--radius-3);
  box-shadow: var(--elevation-diffuse);
  font: var(--font-default);
  color: var(--color-text-light);
  */
}

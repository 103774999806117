.src-components-Form-SelectCreatable---select--3H8oB .select__indicator-separator {
  display: none;
}

.src-components-Form-SelectCreatable---select--3H8oB .inputEl {
  border: none;
  padding: 0;
}

.src-components-Form-SelectCreatable---select--3H8oB .select__control {
  border: 2px solid var(--color-grey-50);
  border-radius: var(--radius-2);
  transition: border 150ms ease;
  box-shadow: none;
}

.src-components-Form-SelectCreatable---select--3H8oB .select__control--is-focused {
  border-color: var(--color-primary) !important;
}

.src-components-Form-SelectCreatable---select--3H8oB .select__value-container {
  padding: 0.556em 0.6em 0.6em;
}

.src-components-Form-SelectCreatable---select--3H8oB .select__option {
  font: var(--font-input);
}

.src-components-Switch---input--20ni5 {
  display: none;
}

.src-components-Switch---switch--1Zp2y {
  display: inline-block;
  font-size: 1rem;
  width: 2.25em;
  padding: 0.15em 0.15em 0.175em;
  background: var(--color-grey-50);
  border-radius: 1em;
  transition: background 200ms ease;
  cursor: pointer
}

.src-components-Switch---switch--1Zp2y[data-checked="true"] {
    background: var(--color-primary);
  }

.src-components-Switch---thumb--3mPIT {
  height: 0.7em;
  width: 0.7em;
  border-radius: 1em;
  background: white;
  transition: -webkit-transform 200ms var(--easing-standard);
  transition: transform 200ms var(--easing-standard);
  transition: transform 200ms var(--easing-standard), -webkit-transform 200ms var(--easing-standard);
}

.src-components-Switch---input--20ni5:checked + .src-components-Switch---thumb--3mPIT {
  -webkit-transform: translateX(calc(2.25em - 150%));
      -ms-transform: translateX(calc(2.25em - 150%));
          transform: translateX(calc(2.25em - 150%));
}

.pro-ui .src-components-Switch---switch--1Zp2y[data-checked="true"] {
      background: var(--color-violet);
    }

.ai-suggestion-input {
  background: var(--color-grey-100) !important;
  zoom: 0.8;
}

.src-components-AuthForm---heading--2yiSo {
  text-align: center;
  font-size: var(--scale-1);
  margin-bottom: 1rem;
}
.src-components-AuthForm---subHeading--1G6Hk {
  text-align: center;
  font-size: var(--scale-0);
  margin-bottom: 1rem;
  font-weight: 400;
}
.src-components-AuthForm---description--116Tc {
  text-align: center;
  color: var(--color-text-light);
}
.src-components-AuthForm---social--2BI24 {
  display: -ms-flexbox;
  display: flex;
  margin: 1.5rem 0;
}
.src-components-AuthForm---social-button--1pyaw {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex: 1 1;
      flex: 1 1;
  font-size: var(--scale-00)
}
.src-components-AuthForm---social-button--1pyaw:first-child {
    margin-right: 0.5rem;
  }
.src-components-AuthForm---social-button-icon--1wj3- {
  height: 1.25em;
  margin-right: 0.5em;
  -webkit-transform: translateY(-5%);
      -ms-transform: translateY(-5%);
          transform: translateY(-5%);
}
.src-components-AuthForm---or_line_wrap--38OPB {
  font-size: 12px;
  font-weight: 400;
  color: rgb(144, 148, 165);
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0
}
.src-components-AuthForm---or_line_wrap--38OPB::before {
    content: "";
    width: 100%;
    height: 0.5rem;
    margin-right: 16px;
    border-bottom: 1px solid rgb(223, 224, 229);
  }
.src-components-AuthForm---or_line_wrap--38OPB::after {
    content: "";
    width: 100%;
    height: 0.5rem;
    margin-left: 16px;
    border-bottom: 1px solid rgb(223, 224, 229);
  }
.src-components-AuthForm---form--2SYVM {
  padding-top: 0.5rem
}
.src-components-AuthForm---form--2SYVM[data-form-action="reset"] {
    border-top: none;
  }
.src-components-AuthForm---name--2JTk0 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  margin: 1rem -0.5rem 0
}
.src-components-AuthForm---name--2JTk0 > div {
    margin: 0 0.5rem;
  }
.src-components-AuthForm---form-input--25zdi,
.src-components-AuthForm---form-submit--31KzQ,
.option-section {
  margin: 1rem 0 !important;
  width: 100% !important;
}
.src-components-AuthForm---guestLogin--PFHnC {
  width: 100%;
}
.src-components-AuthForm---passwordReset--1i_NL {
  font: var(--font-ui);
  cursor: pointer;
  color: var(--color-text-light);
  text-align: right;
  display: block;
  margin-bottom: 0.5rem;
}

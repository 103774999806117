.rmsLoadHide {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 90;
  opacity: 1;
  background: #fbfbfb
}
.rmsLoadHide.deactive {
    transition: all 0.5s ease-in-out 0.2s;
    opacity: 0;
    z-index: -1;
  }
.modalRmsHide .rmsLoadHide {
  margin: 0 0 0 -2.25rem;
  width: calc(100% + 4.5em);
  border-radius: 4px;
}

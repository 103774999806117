
    .src-components-AITokenCounter---token--Q_bB8.sidebar-ai-counter button {
      width: 100%;
      background: rgba(255, 255, 255, 0.4) !important;
      margin: 1rem 0;
      -ms-flex-align: center;
          align-items: center;
      color: var(--color-nav)
    }
.src-components-AITokenCounter---token--Q_bB8.sidebar-ai-counter button i {
        color: var(--color-nav);
      }
.src-components-AITokenCounter---token--Q_bB8.sidebar-ai-counter button b {
        display: inline;
      }
.src-components-AITokenCounter---token--Q_bB8.sidebar-ai-counter button .src-components-AITokenCounter---generator_none_section--1BzbC {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
            justify-content: space-between;
        padding-left: 0.3rem
      }
.src-components-AITokenCounter---token--Q_bB8.sidebar-ai-counter button .src-components-AITokenCounter---generator_none_section--1BzbC i {
          margin: 0 0.3rem;
        }
.src-components-AITokenCounter---token--Q_bB8.sidebar-ai-counter button .src-components-AITokenCounter---generator_section--2fjyp {
        display: none;
      }
.src-components-AITokenCounter---token--Q_bB8.cover-navbar-ai-counter button {
      background: rgba(116, 116, 116, 0.15) !important;
    }
.src-components-AITokenCounter---token--Q_bB8.cover-navbar-ai-counter b {
      display: inline;
    }
.src-components-AITokenCounter---token--Q_bB8 button {
    background: #fff !important;
    color: var(--color-black);
    border: none;
    padding: 0.5rem;
    margin-left: 10px;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    font: var(--font-ui);
    font-size: var(--scale-000);
    border-radius: var(--radius-3);
    text-transform: uppercase;
    cursor: pointer;
    outline: none
  }
.src-components-AITokenCounter---token--Q_bB8 button i {
      color: rgb(85 105 212);
    }
.src-components-AITokenCounter---token--Q_bB8 button > span {
      margin-left: 6px;
      top: 3px !important
    }
.src-components-AITokenCounter---token--Q_bB8 button > span > b {
        display: none;
      }
@media screen and (max-width: 1011px) {
      .src-components-AITokenCounter---token--Q_bB8.cover-navbar-ai-counter button {
        background: none !important
      }
        .src-components-AITokenCounter---token--Q_bB8.cover-navbar-ai-counter button span {
          display: none;
        }
}

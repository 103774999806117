.src-components-Loading---loading-container--2Lep_ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.src-components-Loading---spinner--2xFmn {
  -webkit-animation: src-components-Loading---rotation--1XAPZ 1.35s linear infinite;
  animation: src-components-Loading---rotation--1XAPZ 1.35s linear infinite;
  opacity: 0.6;
}

.src-components-Loading---overlay--27b-J {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background: rgba(255,255,255,0.6);
}

.loading-small {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.loading-small-btn svg {
  width: 20px;
  height: 20px;
}

.loading-small svg {
  width: 20px;
  height: 20px;
}

.loading-small.float-left {
  top: 10px;
  right: -30px;
}

.loading-middle {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.loading-middle-btn svg {
  width: 50px;
  height: 50px;
}

.loading-middle svg {
  width: 50px;
  height: 50px;
}

.loading-middle.float-left {
  top: 10px;
  right: -30px;
}

@-webkit-keyframes src-components-Loading---rotation--1XAPZ {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes src-components-Loading---rotation--1XAPZ {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

.src-components-Loading---circle--I3jiT {
  stroke-dasharray: 180;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-animation: src-components-Loading---turn--2Yprv 1.35s ease-in-out infinite;
  animation: src-components-Loading---turn--2Yprv 1.35s ease-in-out infinite;
}

@-webkit-keyframes src-components-Loading---turn--2Yprv {
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes src-components-Loading---turn--2Yprv {
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 {
  background: #f7f7f7;
  padding: 0.7em;
  margin-bottom: 0.5em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column
}
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---header-detail--3CkhJ {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
  }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 strike {
    padding-right: 6px;
    opacity: 0.5;
  }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---radio-wrapper--1F6B2 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    padding: 8px 0 0;
    margin-right: -8px;
  }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---detail-price-wrapper--YGCED {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-align: baseline;
        align-items: baseline;
    margin-top: 3px;
    font-weight: bold
  }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---detail-price-wrapper--YGCED small {
      font-size: 13px;
      white-space: nowrap;
      color: #959da6;
    }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---detail-price-wrapper--YGCED > div {
      background: #f0f0f0;
      white-space: nowrap;
      color: var(--color-grey-300);
      border-radius: 30px;
      font-size: 0.85em;
      font-weight: 500;
      padding: 3px 10px;
      margin-right: 10px;
    }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---details--3FZgF {
    width: 100%;
  }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---details-list--3T2-I {
    display: none
  }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---details-list--3T2-I[data-open="true"] {
      display: block;
    }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---details-list--3T2-I ul {
      list-style: none;
      padding: 0 0 0 37px;
      margin: 0 0 1em
    }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---details-list--3T2-I ul li {
        font-size: 0.8em;
        padding-left: 8px;
        border-radius: 5px;
        margin-left: -8px;
        transition: background 0.1s ease-in-out;
        cursor: pointer
      }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---details-list--3T2-I ul li.src-components-Checkout-PlanModalRow---highlight--120_M {
          color: var(--color-green);
          font-weight: 600;
        }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---details-list--3T2-I ul li.highlight-hover {
          background: var(--color-grey-50);
        }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---details-list--3T2-I ul li i {
          color: var(--color-green);
          margin-right: 10px;
          font-size: 11px;
        }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---header--NoGYc {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    cursor: pointer;
    padding-left: 4px
  }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---header--NoGYc > span {
      font: var(--font-input);
      padding-top: 8px;
    }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---header--NoGYc i {
      -webkit-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
              transform: rotate(-90deg);
      transition: 0.2s ease-in-out;
      color: var(--color-grey-300);
      padding: 10px
    }
.src-components-Checkout-PlanModalRow---plan-detail-wrapper--1SDV9 .src-components-Checkout-PlanModalRow---header--NoGYc i.open {
        -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
                transform: rotate(0deg);
      }

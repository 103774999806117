.src-components-Form-Range---track--HQM35 {
  height: 2px;
  width: 100%;
  border-radius: 2px;
  background: var(--color-primary);
}

.src-components-Form-Range---thumb--1AVhJ {
  height: 0.75rem;
  width: 0.75rem;
  border-radius: var(--radius-100);
  background: var(--color-primary);
  box-shadow: var(--elevation-flat);
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

.src-components-Form-Field---inputEl--elK7E {
  display: block;
  font: var(--font-input);
  width: 100%;
  color: inherit;
  background: white;
  outline: none;
  margin: 0;
  box-shadow: none;
  padding: 0.85em 0.75em 0.75em;
  border: 2px solid var(--color-grey-50);
  border-radius: var(--radius-2);
  transition: border 150ms ease
}

.src-components-Form-Field---inputEl--elK7E:focus {
    border-color: var(--color-primary);
  }

.src-components-Form-Field---inputEl--elK7E[disabled] {
    opacity: 0.6;
    color: var(--color-text-light);
  }

.src-components-Form-Field---inputEl--elK7E::-webkit-input-placeholder {
    opacity: 0.5;
  }

.src-components-Form-Field---inputEl--elK7E:-ms-input-placeholder {
    opacity: 0.5;
  }

.src-components-Form-Field---inputEl--elK7E::placeholder {
    opacity: 0.5;
  }

.coursework-section.type-description {
  font-weight: 400;
}

.src-components-Form-Field---label--2GfIV {
  display: block;
  font: var(--font-ui);
  font-weight: 400;
  font-size: var(--scale-000);
  color: var(--color-text-light);
  margin-bottom: 1em;
  text-transform: uppercase;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center
}

.src-components-Form-Field---label--2GfIV strong {
    color: var(--color-text);
    word-break: keep-all;
    white-space: nowrap;
  }

.src-components-Form-Field---switchable--1aCsy {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  width: 100%;
  margin: -3px 0;
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden
}

.src-components-Form-Field---switchable--1aCsy span {
    margin-right: 10px;
  }

.src-components-Form-Field---switchable--1aCsy[data-visible="visible"] {
    visibility: visible;
    height: 18px;
    opacity: 1;
  }

.src-components-Form-Field---switchable--1aCsy.src-components-Form-Field---ai-suggestion--3qEI3 {
    width: auto;
    margin-left: 10px
  }

.src-components-Form-Field---switchable--1aCsy.src-components-Form-Field---ai-suggestion--3qEI3 span {
      margin-left: 10px;
    }

.src-components-Form-Field---status--jBqlH {
  display: block;
  margin-top: 1em;
}

.src-components-Form-Field---status--jBqlH {
  font: var(--font-ui);
  opacity: 0;
  color: transparent;
  transition: all 300ms ease
}

.src-components-Form-Field---status--jBqlH::before {
    display: block;
    height: 0;
    overflow: visible;
  }

[required]:valid ~ .src-components-Form-Field---status--jBqlH {
  opacity: 1;
  color: var(--color-green)
}

[required]:valid ~ .src-components-Form-Field---status--jBqlH::before {
    content: attr(data-valid-message);
  }

[data-submitted="true"] *:invalid ~ .src-components-Form-Field---status--jBqlH {
  opacity: 1;
  color: var(--color-red)
}

[data-submitted="true"] *:invalid ~ .src-components-Form-Field---status--jBqlH::before {
    content: attr(data-invalid-message);
  }

.src-components-Form-Radio---checkbox--IPXr8 {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.src-components-Form-Radio---label--2iYE5 {
  color: var(--color-text-light);
}

.src-components-Form-Radio---check--3AkGr {
  width: 1.2em;
  height: 1.2em;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 50px;
  border: 2px solid var(--color-primary);
  transition: background 100ms ease;
  margin-right: 0.5rem
}

.src-components-Form-Radio---check--3AkGr[data-checked="true"] {
    background: var(--color-primary);
    border-color: transparent;
  }

.src-components-Form-Radio---check-icon--14Xb3 {
  height: 0.85em;
  width: 0.85em;
  fill: var(--color-text);
}

.src-components-Form-Checkbox---checkbox--29jLL {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.src-components-Form-Checkbox---label--3KmDr {
  color: var(--color-text-light);
}

.src-components-Form-Checkbox---check--2FuWl {
  width: 1.2em;
  height: 1.2em;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: var(--radius-2);
  border: 2px solid var(--color-primary);
  transition: background 100ms ease;
  margin-right: 0.5rem
}

.src-components-Form-Checkbox---check--2FuWl[data-checked="true"] {
    background: var(--color-primary);
    border-color: transparent;
  }

.src-components-Form-Checkbox---check-icon--3LFXL {
  height: 0.85em;
  width: 0.85em;
  fill: var(--color-text);
}

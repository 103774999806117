.plan-modal {
  padding: 0 1.25rem;
  z-index: 9999
}

.plan-modal > svg {
    fill: #fff;
  }

.plan-modal main {
    margin: 0 0 1em;
    position: relative;
    overflow: hidden
  }

.plan-modal main > div {
      width: 300%;
      display: -ms-flexbox;
      display: flex;
      transition: all 0.5s ease-in-out
    }

.plan-modal main > div > section {
        width: 100%;
        transition: all 0.5s ease-in-out;
        max-height: 1400px;
        height: 100%
      }

.plan-modal main > div > section.src-components-Checkout-PlanModal---collapse--9iXZd {
          height: 0%;
          max-height: 0px;
        }

.plan-modal .src-components-Checkout-PlanModal---plan-detail-wrapper--jITw6 {
    background: #f7f7f7;
    padding: 1.5em 0.7em;
    margin: 1.5em 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between
  }

.plan-modal .src-components-Checkout-PlanModal---plan-detail-wrapper--jITw6 span {
      font: var(--font-input);
    }

.plan-modal .src-components-Checkout-PlanModal---plan-detail-wrapper--jITw6 a {
      width: auto;
      display: -ms-inline-flexbox;
      display: inline-flex;
      position: relative;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      font: var(--font-ui);
      font-size: var(--scale-000);
      border-radius: var(--radius-3);
      background: var(--color-primary);
      text-transform: uppercase;
      font-weight: var(--font-weight-bold);
      padding: 1.1em 1.5em 1em;
      min-width: 7rem;
      text-align: center;
      white-space: nowrap;
      border: none;
      outline: none;
      cursor: pointer;
      -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
      color: white;
      transition: all ease 150ms;
    }

.plan-modal .src-components-Checkout-PlanModal---aiTokenCounter--2zDKb {
    font-weight: 600;
    color: #959da6;
    margin-left: 10px;
    font-size: 0.9em;
    vertical-align: top;
    float: right;
  }

.plan-modal > .src-components-Checkout-PlanModal---header--w50Ll {
    margin: 0 -1.25rem;
    background: var(--color-primary);
    color: #fff;
    font: var(--font-heading);
    font-size: 1em;
    padding: 0.8em 1.25rem;
    border-top-right-radius: var(--radius-3);
    border-top-left-radius: var(--radius-3);
  }

.plan-modal > footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    margin: 0 -1.25em;
    border-top: 1px solid #f3f3f3;
    padding: 1.5em
  }

.plan-modal > footer .src-components-Checkout-PlanModal---total-wrapper--3gEJ5 {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      color: var(--color-grey-500);
      font-size: 0.85em;
      font-weight: 600
    }

.plan-modal > footer .src-components-Checkout-PlanModal---total-wrapper--3gEJ5 > span:first-child {
        color: #959da6;
      }

.plan-modal > footer .src-components-Checkout-PlanModal---total-wrapper--3gEJ5 .src-components-Checkout-PlanModal---total--1pvBR {
        font-size: 1.5em;
        display: -ms-flexbox;
        display: flex
      }

.plan-modal > footer .src-components-Checkout-PlanModal---total-wrapper--3gEJ5 .src-components-Checkout-PlanModal---total--1pvBR strike {
          margin-top: 9px;
          font-size: 0.7em;
        }

.plan-modal > footer .src-components-Checkout-PlanModal---total-wrapper--3gEJ5 .src-components-Checkout-PlanModal---total--1pvBR span {
          width: 100%;
        }

.plan-modal > footer .src-components-Checkout-PlanModal---purchase-wrapper--l-hR2 {
      display: -ms-flexbox;
      display: flex;
      margin: 6px 0;
    }

.src-components-Checkout-PlanModal---section-title--OTOYB {
  font: var(--font-heading);
  font-size: 1em;
  font-weight: 600;
  margin-top: 2em;
}

.src-components-Checkout-PlanModal---add-credits--35tGw {
  display: -ms-flexbox;
  display: flex;
}

.src-components-Checkout-PlanModal---switch-plan-recurrent--1o1qw {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center;
  margin: -22px -10px 0;
  font-size: 0.8em
}

.src-components-Checkout-PlanModal---switch-plan-recurrent--1o1qw span {
    padding: 0 10px;
    font-weight: 600;
    color: #959da6;
  }

.src-components-Checkout-PlanModal---plan-wrapper--2oVLw {
  margin: 1em 0 0;
}

.src-components-Checkout-PlanModal---ghostPayment--3q3oZ span {
    font-size: 1em;
    text-align: center;
    display: block;
    margin: 1em;
  }

.src-components-Checkout-PlanModal---ghostPayment--3q3oZ > div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -ms-flex-pack: justify;
        justify-content: space-between
  }

.src-components-Checkout-PlanModal---ghostPayment--3q3oZ > div > div {
      height: 2.5em;
      border-radius: 8px;
      width: 100%;
      background: #f1f1f1;
      margin: 1em 0 0.5em
    }

.src-components-Checkout-PlanModal---ghostPayment--3q3oZ > div > div:before {
        content: "";
        display: block;
        background: linear-gradient(90deg, #f7f7f7, #f0f0f0, #f7f7f7);
        background-size: 400% 400%;
        -webkit-animation: src-components-Checkout-PlanModal---loadingGradient--3AWvA 2s ease infinite;
                animation: src-components-Checkout-PlanModal---loadingGradient--3AWvA 2s ease infinite;
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }

.src-components-Checkout-PlanModal---ghostPayment--3q3oZ > div > div.src-components-Checkout-PlanModal---half--3Xf6q {
        width: calc(50% - 1em);
      }

.src-components-Checkout-PlanModal---success--2jLj7 {
  margin-top: 1em
}

.src-components-Checkout-PlanModal---success--2jLj7 h1 {
    text-align: center;
  }

.src-components-Checkout-PlanModal---success--2jLj7 p {
    text-align: center;
    font-size: 0.8em;
    padding: 0 20%;
  }

.src-components-Checkout-PlanModal---success--2jLj7 .src-components-Checkout-PlanModal---success-anim--1KGMx {
    height: 175px;
    position: relative
  }

.src-components-Checkout-PlanModal---success--2jLj7 .src-components-Checkout-PlanModal---success-anim--1KGMx i {
      position: absolute;
      left: calc(50% - 67.5px);
      top: 30px;
      font-size: 135px
    }

.src-components-Checkout-PlanModal---success--2jLj7 .src-components-Checkout-PlanModal---success-anim--1KGMx i.fa-badge-check {
        color: var(--color-green);
        opacity: 1;
        /*animation: rotate 1s ease forwards;*/
        --fa-secondary-opacity: 0.3;
      }

@-webkit-keyframes src-components-Checkout-PlanModal---loadingGradient--3AWvA {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 101%;
  }
  100% {
    background-position: 0% 1000%;
  }
}

@keyframes src-components-Checkout-PlanModal---loadingGradient--3AWvA {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 101%;
  }
  100% {
    background-position: 0% 1000%;
  }
}

@-webkit-keyframes src-components-Checkout-PlanModal---rotate--2ybMc {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    --fa-primary-color: #fbfbfb;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    --fa-primary-color: var(--color-green);
  }
}

@keyframes src-components-Checkout-PlanModal---rotate--2ybMc {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    --fa-primary-color: #fbfbfb;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    --fa-primary-color: var(--color-green);
  }
}

.src-components-Checkout-PlanModal---video-module--2M_Vz {
  display: -ms-flexbox;
  display: flex;
  width: 100% !important;
  max-height: 280px !important;
}

.src-components-Modal---modal--1XIEU {
  display: inline-block;
  position: relative;
  outline: none !important;
  max-width: 30rem;
  width: 100%;
  border: none;
  /* HACK copy card styles for now (need ref through modal portal) */
  background: #fbfbfb;
  box-shadow: var(--elevation-flat);
  border-radius: var(--radius-3);
  padding: var(--gutter);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: center;
      justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  padding: var(--gutter);
  overflow: scroll;
  z-index: var(--layer-top);
}

.upgrade-popup {
  -ms-flex-align: start !important;
      align-items: flex-start !important;
}

.src-components-Modal---modal-close--2Wa8F {
  z-index: 1000;
  position: absolute;
  top: 1em;
  right: 1em;
  height: var(--scale-00);
  width: var(--scale-00);
  fill: var(--color-text-light);
  cursor: pointer;
  transition: fill 200ms ease
}

.src-components-Modal---modal-close--2Wa8F:hover {
    fill: var(--color-text);
  }

.scoreModal .src-components-Modal---modal-close--2Wa8F {
  z-index: 2;
  position: fixed;
  width: 20px;
  height: 20px;
}

.upgrade-popup .src-components-Modal---modal-close--2Wa8F {
  top: 1rem;
}

/**
 * Transitions
 */

.src-components-Modal---modal--1XIEU,
.modal-overlay {
  transition: opacity 300ms var(--easing-standard);
}

.src-components-Modal---modal--1XIEU,
.modal-overlay.open,
.modal-overlay.open.closing .src-components-Modal---modal--1XIEU,
.modal-overlay.open .src-components-Modal---modal--1XIEU {
  opacity: 1;
}

.modal-overlay,
.modal-overlay.open.closing {
  opacity: 0;
}

.writing-assistant-modal {
  max-width: 45rem;
}

.modal-overlay.checkoutmodal {
  z-index: 9999;
}

@media screen and (max-width: 30rem) {
  .modal-overlay.checkoutmodal {
    padding: 0 0 5em;
  }
}

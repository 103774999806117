.src-components-Checkout-Coupon---checkoutForm-coupon--1G_cS {
  margin: 2em 0 0;
}
.src-components-Checkout-Coupon---coupon--2XEA4 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  font: var(--font-ui);
  padding: 0 0.2em;
  margin-top: 0.5rem;
}
.src-components-Checkout-Coupon---coupon-check--2UHA4 {
  color: var(--color-text-light);
  cursor: pointer;
}
.src-components-Checkout-Coupon---coupon-link--3iuAj {
  text-align: right;
  font-size: 0.8em;
  color: var(--color-primary);
  cursor: pointer
}
.src-components-Checkout-Coupon---coupon-link--3iuAj:hover {
    text-decoration: underline;
  }
.src-components-Checkout-Coupon---coupon-result--sSZ43 {
  color: var(--color-green)
}
.src-components-Checkout-Coupon---coupon-result--sSZ43.alert {
    color: var(--color-red);
  }

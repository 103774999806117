.src-components-Button---button--3Sq9I {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  font: var(--font-ui);
  font-size: var(--scale-000);
  border-radius: var(--radius-3);
  background: var(--color-primary);
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  padding: 1.1em 1.5em 1em;
  min-width: 7rem;
  text-align: center;
  white-space: nowrap;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: white;
  transition: all ease 150ms
}
@media (min-width: 40em) {
.src-components-Button---button--3Sq9I {
    width: auto;
    display: -ms-inline-flexbox;
    display: inline-flex
}
  }
/* Sizes */
.src-components-Button---button--3Sq9I[data-size="x-small"] {
  padding: 0.8em 1em;
  min-width: 0;
  font-size: 0.6rem;
}
.src-components-Button---button--3Sq9I[data-size="small"] {
  padding: 0.8em 1.3em;
  min-width: 5rem;
}
.src-components-Button---button--3Sq9I[data-size="big"],
.src-components-Button---button--3Sq9I[data-size="large"] {
  padding: 1.45em 1.8em 1.35em;
}
/* Themes */
.src-components-Button---button--3Sq9I[data-theme="inverse"] {
  background: white;
  color: var(--color-primary);
  box-shadow: var(--elevation-flat);
}
.src-components-Button---button--3Sq9I[data-theme="secondary"] {
  background: white;
  color: var(--color-text);
  box-shadow: var(--elevation-flat);
  border: 2px solid var(--color-grey-50);
}
/* States */
.src-components-Button---button--3Sq9I[data-busy="true"] {
  opacity: 0.5;
  pointer-events: none;
}
.src-components-Button---button--3Sq9I[disabled] {
  pointer-events: none;
  background: white !important;
  color: var(--color-grey-100) !important;
  box-shadow: none !important;
  border: 1px solid var(--color-grey-50);
}
.pro-ui .src-components-Button---button--3Sq9I {
    background: var(--color-violet);
  }
.pro-ui .src-components-Button---button--3Sq9I[data-theme="secondary"] {
    background: white;
  }
.pro-ui .src-components-Button---button--3Sq9I[data-theme="inverse"] {
    background: white;
  }

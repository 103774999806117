/*:global(.async-hide) {*/
/*  opacity: 0 !important;*/
/*}*/
.src-containers-App---page--3PFgY {
  width: 100%;
}
.src-containers-App---background-gray--1kU_O {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1
}
.src-containers-App---background-gray--1kU_O::before,
  .src-containers-App---background-gray--1kU_O::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.78;
  }
.src-containers-App---background-gray--1kU_O::before {
    background-image: linear-gradient(
      3deg,
      #f8f9fa 31%,
      rgba(239, 253, 248, 0) 97%
    );
    background-size: contain;
    width: 125%;
    height: 100%;
    top: -170px;
    left: -10%;
    -webkit-transform: rotate(5deg);
        -ms-transform: rotate(5deg);
            transform: rotate(5deg);
  }
.src-containers-App---background-gray--1kU_O::after {
    background-image: linear-gradient(1deg, #e9ecef 0, #fff 100%);
    background-size: cover;
    width: 120%;
    height: 120%;
    top: -60%;
    left: 1%;
    -webkit-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
.src-containers-App---background--1xoMO {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1
}
.src-containers-App---background--1xoMO::before,
  .src-containers-App---background--1xoMO::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.78;
  }
.src-containers-App---background--1xoMO::before {
    background-image: linear-gradient(
      3deg,
      #e5f8f4 31%,
      rgba(239, 253, 248, 0) 97%
    );
    background-size: contain;
    width: 125%;
    height: 100%;
    top: -170px;
    left: -10%;
    -webkit-transform: rotate(5deg);
        -ms-transform: rotate(5deg);
            transform: rotate(5deg);
  }
.src-containers-App---background--1xoMO::after {
    background-image: linear-gradient(1deg, #dfeefd 0, #fff 100%);
    background-size: cover;
    width: 120%;
    height: 120%;
    top: -60%;
    left: 1%;
    -webkit-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
.src-containers-App---splash--30lPB {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 100%;
  height: 100vh;
}
.src-containers-App---splash-logo--2eVy9 {
  height: 4rem;
  opacity: 0;
  -webkit-animation: src-containers-App---fadeIn--1tFb1 500ms ease forwards;
          animation: src-containers-App---fadeIn--1tFb1 500ms ease forwards;
}
@-webkit-keyframes src-containers-App---fadeIn--1tFb1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes src-containers-App---fadeIn--1tFb1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.src-containers-App---hamburger--5go4_ {
  position: relative;
  top: var(--gutter);
  left: 1em;
  z-index: 1;
  display: grid;
  grid-template-columns: var(--grid-page);
}
.pro-ui {
  --color-primary: var(--color-violet);
  background: #fbfbfb;
}
.pro-ui .src-containers-App---background--1xoMO {
    display: none;
  }
.pro-ui aside {
    background: #3c2e8d
  }
.pro-ui aside .cta-button i {
      color: var(--color-violet) !important;
    }
.cta-button {
  transition: all 0s ease-in-out;
}
.src-containers-App---updateTopBar--1DBw7 {
  position: fixed;
  width: 100%;
  z-index: 10;
  padding: 9px 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  top: -67px;
  transition: 1s all ease-in-out
}
.src-containers-App---updateTopBar--1DBw7.src-containers-App---active--9x4gL {
    top: 0;
  }
.src-containers-App---versionApp--b9idy {
  position: absolute;
  z-index: -4;
  bottom: 0;
  right: 5px;
  font-size: 11px;
  color: #adadad;
}
.handle {
  position: absolute !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center;
      align-items: center;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  height: 100%;
}
.draggable-item > .handle-sort-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  position: absolute;
  left: -20px;
  width: 20px;
  cursor: grab;
}
.draggable-item > .handle-sort-wrapper > span {
  font-size: 1.35em;
  visibility: hidden;
}
.draggable-item section .handle-sort-wrapper > span {
  font-size: 1.1em;
  line-height: 2em;
  visibility: hidden;
}
.draggable-item {
  position: relative;
}
.draggable-item:hover
  > .handle-sort-wrapper
  > .handle {
  opacity: 1;
}
.draggable-item
  > .draggable-item:hover
  > .handle-sort-wrapper
  > .handle {
  opacity: 1;
}
